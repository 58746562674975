import React from 'react';
import { Toolbar, AppBar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const debug = (
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    ));

const Header = ({ }) => {
    return (
        <AppBar position="static" style={{ marginBottom: 1 + 'em', backgroundColor: '#9d2235' }}>
            <Toolbar>
                <Typography
                    variant="h6"
                    sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                >
                    <Link to="/" style={{ 'text-decoration': 'none', color: '#fff'}}>
                    UAMS Radiation Dosimeter Request
                    </Link>
                </Typography>
            {debug && (
                <>
                    <Typography variant="h6">
                        Dev
                    </Typography>
                </>
            )}
            </Toolbar>
        </AppBar >
    );
};

export default Header;