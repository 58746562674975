import React, { Component } from 'react';
import { Typography, Box } from '@mui/material';
import WorkdayLogo from '../Assets/Workday.png';

export class Thanks extends Component {
    static displayName = Thanks.name;
    render() {
        return (
            <div>
                <Typography variant="h4" align="Center" gutterBottom="true">
                    Thank you for your request!
                </Typography>
                <div>
                    <Typography paragraph align="Center">
                        We have received your request and will be in touch when your dosimeter is ready to pick up.
                    </Typography>
                    <Typography paragraph>
                        Please complete these steps if you have not already done so:
                        <span>
                            <ul>
                                <li>Please submit a Radiation Dosimeter request work order by using this <span><a href="https://uams.webtma.com/?tkn=k2T0y2XBcF8qI0qvugaegOsG6r_r_QLy1rofWww2bkR2yIbuxVjH5QpMv2xRnjc0ZMDX5kcTyF5NZqJ5Rncr70iIW9UT2RP8TAMgeXJeFRMjfauAQQYNunbbhDgx0TcO0rhZd5kuQqP4yEzVSoT3Ga74onu6wcevDJXIIpcMBrVsagrvB8AmdhXr6xiF3uTwGjy8D2LwAqjvbAHjpthRSIChHcawnLv46I3Nn6prgR48q5GTJK3ohIg6wisFajoPXKCJVpKmeoVma1nydAHqew">link</a></span> or contact the UAMS Call Center at 501-526-0000.</li>
                                <li>Complete the <span><a href="https://wd5.myworkday.com/uasys/learning/course/76f169a3e8f410185f7f4ffdb9200000?record=479603b54bf8101bc3536e32dc710000&type=9882927d138b100019b928e75843018d">Personnel Monitoring for Radiation Exposure 101 Training</a></span> in Workday.</li>
                                <li style={{ listStyleType: 'none' }}>NOTE:  If you are not logged in to Workday, your UAMS My Apps page displays. Click the Workday icon (<Box
                                    component="img"
                                    sx={{
                                        height: 215,
                                        width: 215,
                                        maxHeight: 20,
                                        maxWidth: 20,
                                    }}
                                    alt="Workday Logo"
                                    src={WorkdayLogo}
                                />) to load the training.</li>
                            </ul>
                        </span>
                    </Typography>
                </div>
            </div>
        )
    }
}