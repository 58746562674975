import React, { Component } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { Layout } from './components/Layout';
import { Registration } from './components/Registration';
import { Thanks } from './components/Thanks';
import { Error } from './components/Error';
import { NotFound } from './components/NotFound';
import { Route, Switch, withRouter } from 'react-router-dom';

import './custom.css'

class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Switch>
                    <Route exact path="/" component={Registration} />
                    <Route exact path="/registration" component={Registration} />
                    <Route exact path="/thanks" component={Thanks} />
                    <Route exact path="/error" component={Error} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </Layout>
        );
    }
}

export default withRouter(App);
