import React, { Component } from 'react';
import { Container, Card, CardContent } from '@mui/material';
import Header from './Header';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <Header/>
                <Container>
                    <Card style={{ marginBottom: 1 + 'em' }}>
                        <CardContent>
                            {this.props.children}
                        </CardContent>
                    </Card>
                </Container>
            </div>
        );
    }
}
