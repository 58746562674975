import React, { Component } from 'react';
import { Formik, Form, FieldArray, getIn } from 'formik';
import { Button, Typography, Divider, Grid, Card, CardContent, TextField } from '@mui/material';
import * as Yup from 'yup';
import CTextField from './CustomInputs/TextField';
import Select from './CustomInputs/Select';
import Checkbox from './CustomInputs/Checkbox';
import DateTimePicker from './CustomInputs/DateTimePicker';
import Sexes from '../data/Sexes.json';
import WorkTypes from '../data/WorkTypes.json';
import dayjs from 'dayjs';
import { PatternFormat, NumericFormat } from 'react-number-format';

const initialSchema = {
    FirstName: '',
    LastName: '',
    MiddleInitial: '',
    BirthDate: '',
    EmployeeId: '',
    StudentId: '',
    Sex: '',
    SupervisorName: '',
    Department: '',
    RoomNumber: '',
    ExtensionNumber: '',
    MailSlot: '',
    Fund: '',
    CostCenter: '',
    BadgeAccount: '',
    WorkType: '',
    OtherExposure: false,
    Agreement: false,
    Histories: [],
};

const ValidationSchema = Yup.object().shape({
    FirstName: Yup.string()
        .max(50, 'May not exceed than 50 characters')
        .required('Required'),
    LastName: Yup.string()
        .max(50, 'May not exceed than 50 characters')
        .required('Required'),
    MiddleInitial: Yup.string()
        .max(1, 'Must be exactly 1 character'),
    BirthDate: Yup.date()
        .max(new dayjs().toDate(), 'Select a valid date')
        .min(new dayjs().subtract(130, 'year').toDate(), 'Select a valid date')
        .required('Required'),
    EmployeeId: Yup.string().length(7, 'Must be 7 digits'),
    StudentId: Yup.string().length(7, 'Must be 7 digits'),
    Sex: Yup.string()
        .required('Required'),
    SupervisorName: Yup.string()
        .max(100, 'May not exceed 100 characters')
        .required('Required'),
    Department: Yup.string()
        .min(2, 'Must be at least 2 characters')
        .max(100, 'May not exceed 100 characters')
        .required('Required'),
    RoomNumber: Yup.string()
        .max(50, 'May not exceed 50 characters'),
    ExtensionNumber: Yup.string()
        .min(2, 'Too Short')
        .max(10, 'Too Long'),
    MailSlot: Yup.string()
        .min(2, 'Too Short')
        .max(10, 'Too Long'),
    Fund: Yup.string()
        .max(5, 'May not exceed 3 characters'),
    CostCenter: Yup.string()
        .max(7, 'May not exceed 5 characters'),
    BadgeAccount: Yup.string()
        .min(1, 'Must be at least 1 character')
        .max(3, 'May not exceed 3 characters')
        .required('Required'),
    WorkType: Yup.string()
        .required('Required'),
    OtherExposure: Yup.bool(),
    Agreement: Yup.bool()
        .isTrue('Required'),
    Histories: Yup.array()
        .of(Yup.object()
            .shape({
                Employer: Yup.string()
                    .required('Required'),
                Supervisor: Yup.string()
                    .required('Required'),
                Phone: Yup.string()
                    .min(13, 'Enter a valid phone number')
                    .required('Required'),
                Email: Yup.string()
                    .email('Enter a valid email').required('Required'),
                ExposureDate: Yup.date()
                    .max(new dayjs().toDate(), 'Select a valid date')
                    .min(new dayjs().subtract(130, 'year').toDate(), 'Select a valid date')
                    .required('Required'),
                EndDate: Yup.date()
                    .nullable().default(null)
                    .min(Yup.ref('ExposureDate'), 'End date must not be before start date')
                    .max(new dayjs().toDate(), 'Select a valid date'),
            }), [['ExposureDate', 'EndDate']])
}, [['StudentId', 'EmployeeId']]);

const debug = (
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    ));

let count = 0;

export class Registration extends Component {
    static displayName = Registration.name;
    render() {
        const { history } = this.props;
        return (
            <div>
                <Formik
                    initialValues={initialSchema}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, { setSubmitting }) => {

                        setSubmitting(true);

                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(values)
                        };
                        const response = await fetch('api/registration', requestOptions);
                        if (response.status === 200) {
                            history.push('/thanks');
                        }
                        else {
                            history.push('/error');
                        }
                    }}>

                    {({ isSubmitting, values, touched, errors, handleChange, handleBlur, isValid, props }) => (
                        <div>
                            <Form>
                                <Typography variant="h4">
                                    Radiation Dosimeter Request Form
                                </Typography>

                                <Typography paragraph>
                                    To comply with section RH-1500(d)(1) of the Arkansas Rules and Regulations for Control of Sources of Ionizing Radiation, complete this form. RH-1500(f)(4) protects this information from public disclosure.
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <CTextField
                                            name="FirstName"
                                            label="First Name"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CTextField
                                            name="LastName"
                                            label="Last Name"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CTextField
                                            name="MiddleInitial"
                                            label="Middle Initial"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <PatternFormat
                                            format="#######"
                                            mask=""
                                            name="EmployeeId"
                                            label="Employee ID"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            customInput={CTextField} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <PatternFormat
                                            format="#######"
                                            mask=""
                                            name="StudentId"
                                            label="Student ID"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            customInput={CTextField} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Select
                                            name='Sex'
                                            label='Sex'
                                            options={Sexes}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <DateTimePicker
                                            name="BirthDate"
                                            label="Date of Birth"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CTextField
                                            name="SupervisorName"
                                            label="Supervisor Name"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CTextField
                                            name="Department"
                                            label="Department"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CTextField
                                            name="RoomNumber"
                                            label="Room Number"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CTextField
                                            name="ExtensionNumber"
                                            label="Extension Number"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CTextField
                                            name="MailSlot"
                                            label="Mail Slot"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <PatternFormat
                                            format="FD###"
                                            allowEmptyFormatting
                                            mask=""
                                            name="Fund"
                                            label="Fund"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            customInput={CTextField} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <PatternFormat
                                            format="CC#####"
                                            allowEmptyFormatting
                                            mask=""
                                            name="CostCenter"
                                            label="Cost Center"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            customInput={CTextField} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CTextField
                                            name="BadgeAccount"
                                            label="Dosimeter Subaccount"
                                            />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Select
                                            name='WorkType'
                                            label='Select Work Type'
                                            options={WorkTypes}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Checkbox
                                            name="OtherExposure"
                                            label="If you have ever worked at a facility where you received occupational exposure to radiation, click here. Enter the two most recent locations, which can include your current employer."
                                        />
                                    </Grid>

                                    {values.OtherExposure === true &&
                                        <Grid item xs={12}>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Typography sx={{ fontSize: 16 }} component="div">
                                                        Exposure History
                                                    </Typography>
                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                        Enter the last two work locations where you were exposed to radiation
                                                    </Typography>
                                                    <FieldArray name="Histories">
                                                        {({ push, remove }) => (
                                                            <div>
                                                                {values.Histories.map((p, index) => {
                                                                    const Employer = `Histories[${index}].Employer`;
                                                                    const touchedEmployer = getIn(touched, Employer);
                                                                    const errorEmployer = getIn(errors, Employer);

                                                                    const Supervisor = `Histories[${index}].Supervisor`;
                                                                    const touchedSupervisor = getIn(touched, Supervisor);
                                                                    const errorSupervisor = getIn(errors, Supervisor);

                                                                    const Phone = `Histories[${index}].Phone`;
                                                                    const touchedPhone = getIn(touched, Phone);
                                                                    const errorPhone = getIn(errors, Phone);

                                                                    const Email = `Histories[${index}].Email`;
                                                                    const touchedEmail = getIn(touched, Email);
                                                                    const errorEmail = getIn(errors, Email);

                                                                    const ExposureDate = `Histories[${index}].ExposureDate`;
                                                                    const touchedExposureDate = getIn(touched, ExposureDate);
                                                                    const errorExposureDate = getIn(errors, ExposureDate);

                                                                    const EndDate = `Histories[${index}].EndDate`;
                                                                    const touchedEndDate = getIn(touched, EndDate);
                                                                    const errorEndDate = getIn(errors, EndDate);

                                                                    const Badged = `Histories[${index}].Badged`;

                                                                    return (
                                                                        <div key={p.index} style={{ marginBottom: '1em' }}>
                                                                            <Divider style={{ marginTop: '1em', marginBottom: '1em' }} />
                                                                            <Grid container spacing={2}>
                                                                                <Grid item xs={6}>
                                                                                    <TextField
                                                                                        name={Employer}
                                                                                        label="Employer"
                                                                                        value={p.Employer}
                                                                                        helperText={
                                                                                            touchedEmployer && errorEmployer
                                                                                                ? errorEmployer
                                                                                                : ""
                                                                                        }
                                                                                        error={Boolean(touchedEmployer && errorEmployer)}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        fullWidth
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <TextField
                                                                                        name={Supervisor}
                                                                                        label="Supervisor"
                                                                                        value={p.Supervisor}
                                                                                        helperText={
                                                                                            touchedSupervisor && errorSupervisor
                                                                                                ? errorSupervisor
                                                                                                : ""
                                                                                        }
                                                                                        error={Boolean(touchedSupervisor && errorSupervisor)}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        fullWidth
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={3}>
                                                                                    <PatternFormat
                                                                                        format="(###)###-####"
                                                                                        allowEmptyFormatting
                                                                                        mask=""
                                                                                        name={Phone}
                                                                                        label="Phone #"
                                                                                        value={p.Phone}
                                                                                        helperText={
                                                                                            touchedPhone && errorPhone
                                                                                                ? errorPhone
                                                                                                : ""
                                                                                        }
                                                                                        error={Boolean(touchedPhone && errorPhone)}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        fullWidth
                                                                                        customInput={CTextField} />
                                                                                </Grid>
                                                                                <Grid item xs={3}>
                                                                                    <TextField
                                                                                        name={Email}
                                                                                        label="Email Address"
                                                                                        value={p.Email}
                                                                                        helperText={
                                                                                            touchedEmail && errorEmail
                                                                                                ? errorEmail
                                                                                                : ""
                                                                                        }
                                                                                        error={Boolean(touchedEmail && errorEmail)}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        fullWidth
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={3}>
                                                                                    <DateTimePicker
                                                                                        name={ExposureDate}
                                                                                        label="Start Date"
                                                                                        value={p.ExposureDate}
                                                                                        type='date'
                                                                                        variant='outlined'
                                                                                        helperText={
                                                                                            touchedExposureDate && errorExposureDate
                                                                                                ? errorExposureDate
                                                                                                : ""
                                                                                        }
                                                                                        error={Boolean(touchedExposureDate && errorExposureDate)}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        fullWidth
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={3}>
                                                                                    <DateTimePicker
                                                                                        name={EndDate}
                                                                                        label="End Date - Leave empty if current"
                                                                                        value={p.EndDate}
                                                                                        type='date'
                                                                                        variant='outlined'
                                                                                        helperText={
                                                                                            touchedEndDate && errorEndDate
                                                                                                ? errorEndDate
                                                                                                : ""
                                                                                        }
                                                                                        error={Boolean(touchedEndDate && errorEndDate)}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        fullWidth
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <Checkbox
                                                                                        name={Badged}
                                                                                        label="Click if you were badged"
                                                                                        value={p.Badged}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <Button
                                                                                        margin="normal"
                                                                                        type="button"
                                                                                        color="warning"
                                                                                        variant="outlined"
                                                                                        onClick={() => {
                                                                                            remove(index);
                                                                                            count--;
                                                                                        }}
                                                                                    >
                                                                                        Remove Entry
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>
                                                                    );
                                                                })}
                                                            {count < 2 && (
                                                                <>
                                                                    <Button
                                                                        type="button"
                                                                        variant="outlined"
                                                                        onClick={() => {
                                                                            push({ Employer: "", Supervisor: "", Phone: "", Email: "", ExposureDate: "", EndDate: null, Badged: false });
                                                                            count++;
                                                                        }
                                                                        }
                                                                    >
                                                                        Add
                                                                    </Button>
                                                                </>
                                                                )}
                                                                
                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    }

                                    <Grid item xs={12}>
                                        <Checkbox
                                            name="Agreement"
                                            label="I certify that the information listed above is correct and complete to the best of my knowledge. I authorize (a) any employers listed above to provide the University of Arkansas for Medical Sciences with a copy of my occupational radiation exposure history, and (b) UAMS to seek such histories."
                                        />
                                    </Grid>
                                </Grid>

                                <Button variant="outlined" disabled={isSubmitting || values.Agreement === false} style={{ marginTop: 1 + 'em' }} type="submit">Submit</Button>

                                {debug && (
                                    <>
                                        <Divider style={{ marginTop: 1 + 'em', marginBottom: 2 + 'em' }} />
                                        <Button
                                            variant="outlined"
                                            onClick={async () => {
                                                const requestOptions = {
                                                    method: 'POST',
                                                    headers: { 'Content-Type': 'application/json' },
                                                    body: ''
                                                };
                                                const response = await fetch('api/registration/test', requestOptions);
                                                if (response.status === 200) {
                                                    alert('Sent');
                                                }
                                                else {
                                                    history.push('/error');
                                                }
                                            }}
                                        >
                                            Test Email
                                        </Button>
                                        <pre style={{ textAlign: "left" }}>
                                            <strong>Values</strong>
                                            <br />
                                            {JSON.stringify(values, null, 2)}
                                        </pre>
                                        <pre style={{ textAlign: "left" }}>
                                            <strong>Errors</strong>
                                            <br />
                                            {JSON.stringify(errors, null, 2)}
                                        </pre>
                                    </>
                                )}
                            </Form>
                        </div>
                    )}
                </Formik>
            </div>
        )
    }
}