import React, { Component } from 'react';
import { Typography } from '@mui/material';

export class NotFound extends Component {
    static displayName = NotFound.name;
    render() {
        return (
            <div>
                <Typography variant="h4" align="Center" gutterBottom="true">
                    The page you are looking for does not exist
                </Typography>
                <div>
                    <Typography paragraph align="Center">
                        Navigate back on your browser or click on the header to go home
                    </Typography>
                </div>
            </div>
        )
    }
}