import React, { Component } from 'react';
import { Typography } from '@mui/material';

export class Error extends Component {
    static displayName = Error.name;
    render() {
        return (
            <div>
                <Typography variant="h4" align="Center" gutterBottom="true">
                    Something went wrong
                </Typography>
                <div>
                    <Typography paragraph>
                        We have experienced an unexpected error. To request a radiation dosimeter badge, please contact:
                        <span>
                            <ul>
                                <li>Laura Hanson at +(501) 686-7803 or LHanson@uams.edu</li>
                                <li>Crystal Robinson, CRobinson2@uams.edu</li>
                            </ul>
                        </span>
                    </Typography>
                </div>
            </div>
        )
    }
}